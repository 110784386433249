<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn  }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc">
        {{thematicDesc}}
      </div>
      <div class="card_panel" v-for="(clItem,lIdx) in cards" :key="lIdx">
        <div class="card_item" v-for="(card,cIdx) in clItem" :key="cIdx">
          <div class="card_label">
            {{card.name}}
          </div>
          <div class="card_content">
            <div class="card_row" v-for="(rItem,rIdx) in card.features" :key="rIdx">
              <div class="card_line" v-for="(lItem,lIdx) in rItem" :key="lIdx">
                <div class="card_line_icon"></div>
                <div class="card_line_value">
                  {{lItem}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "thematicDesc":"",
      "cards":[],
      "titleZh":"",
      "titleEn":""
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      // let lang = this.$i18n.locale;
      return "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeFeatures&secondaryTitle=featuresOfSaiFu`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.thematicDesc = configData.thematicDesc;
          _this.cards = configData.cards;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
          
        }else{
          _this.$notify({
            title: '失败',
            message: "赛赋特色数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 43px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 70px;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 32px;
      margin-bottom: 28px;
    }
    .card_panel{
      display: flex;
      margin-bottom: 40px;
      .card_item{
        flex: 1;
        overflow: hidden;
        margin-right: 35px;
        padding: 22px 50px;
        background: #F6F7F9;
        &:last-child{
          margin-right: 0;
        }
        .card_label{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 24px;
          color: #0086D1;
          line-height: 53px;
          margin-bottom: 8px;
        }
        .card_content{
          display: flex;
          .card_row{
            flex: 1;
            overflow: hidden;
            .card_line{
              display: flex;
              align-items: flex-start;
              .card_line_icon{
                width: 15px;
                height: 15px;
                background: url("./images/icon@checked.png") 0 0 no-repeat;
                background-size: 100% auto;
                background-position: center left;
                margin: 10px 7px 10px 0;
              }
              .card_line_value{
                flex: 1;
                overflow: hidden;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                line-height: 35px;
              }
            }
          }
        }
      }
    }
  }
}
</style>